import React, { useEffect } from "react";
import { useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Card from "react-bootstrap/esm/Card";
import { fetchPublicUsers } from "../API";
import { Link } from "react-router-dom";

const PublicUserList: React.FC<{}> = () => {
  let [publicUsers, setPublicUsers] = useState<PublicUser[]>([]);

  useEffect(() => {
    fetchPublicUsers()
      .then((users) => {
        setPublicUsers(users.data.users);
      })
      .catch((err) => {
        console.error("Error fetching public users: " + err);
      });
  }, []);

  console.log("PublicUserList: " + JSON.stringify(publicUsers));

  return (
    <Container>
      <h1>Public Users</h1>
      <p>
        You can browse notifications recieved by these users and register to get
        push notifications.
      </p>
      {publicUsers.map((user) => (
        <Card className="public-user-card">
          <Card.Header>
            <h4>
              <Link to={"" + user.username}>{user.username}</Link>
            </h4>
          </Card.Header>
          <Card.Body>{user.description}</Card.Body>
        </Card>
      ))}
    </Container>
  );
};

export default PublicUserList;
