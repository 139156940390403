import { setPushSubscription } from "../API";

const urlB64ToUint8Array = (base64String: string) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export async function setupPushNotifications() {
  return new Promise<void>((resolve, reject) => {
    if (
      !("Notification" in window) &&
      !("serviceWorker" in navigator) &&
      "PushManager" in window
    ) {
      reject("Notifications are disabled");
    } else {
      navigator.serviceWorker.register("pushService.js");
      askPermission().then((result) => {
        navigator.serviceWorker.ready.then(async function (registration) {
          const serverKey = urlB64ToUint8Array(
            "BDZ6TddU83sFuLYjzBtmL4sbELFt_oYoUje8GNygSDhWTEXu4DZ9ne3ypWsoHgHfO4LdiiLpz7vz2T1NkJwrmXM"
          );
          const options = {
            applicationServerKey: serverKey,
            userVisibleOnly: true,
          };
          try {
            // make sure any current subscription is unsubscribed
            let unsubscribeSuccess = await registration.pushManager
              .getSubscription()
              .then((s) => {
                if (s) {
                  console.log("Unsubscribe from current push subscription");
                  return s.unsubscribe();
                } else {
                  return true;
                }
              });
            console.log("Is ready for new subscription: " + unsubscribeSuccess);
            const subscription = await registration.pushManager.subscribe(
              options
            );
            let result = await setPushSubscription(subscription);
            console.log(
              "Successfully setup push notifications: " + JSON.stringify(result)
            );
            registration.showNotification("You will now receive notifications");
            resolve();
          } catch (err) {
            registration.pushManager
              .getSubscription()
              .then((s) => {
                return s?.unsubscribe();
              })
              .then((b) => {
                console.log("unsubscribed");
              })
              .catch((err) => {
                console.error("Error removing subscription: " + err);
              });

            console.error("Error setting up push notifications: " + err);
            reject(err);
          }
        });
      });
    }
  });
}

function askPermission(): Promise<void> {
  return new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(function (result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then(function (permissionResult) {
    if (permissionResult !== "granted") {
      throw new Error("We weren't granted permission.");
    }
  });
}
