import React, { useEffect, useState } from "react";
import Widget from "./Widget";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Alert } from "react-bootstrap";
import { fetchWidgets, getProviders } from "../../API";
import NewNotificationSocketListener from "../../NewNotificationSocketListener";

type Props = {
  user: User;
  advancedMode: boolean;
};
let newNotificationSocketListener = new NewNotificationSocketListener();

const WidgetView: React.FC<Props> = ({ user, advancedMode }) => {
  const [widgetViewState, setWidgetViewState] = useState({
    syncTs: undefined as number | undefined,
    widgets: [] as IDisplayWidget[],
    providers: [] as INotificationProvider[],
  });

  useEffect(() => {
    syncWidgets();
  }, []);

  newNotificationSocketListener.setNewNotificationListener((notification) => {
    if (
      widgetViewState.widgets.length == 0 ||
      user._id != notification.userId
    ) {
      return;
    }
    if (
      widgetViewState.widgets.some(
        (w) => w.config.notificationProviderName == notification.type
      )
    ) {
      // a notification from one of our providers has been updated, resync widgets
      syncWidgets();
    }
  });

  const syncWidgets = () => {
    Promise.all([fetchWidgets(), getProviders()])
      .then((values) => {
        const widgetRes = values[0];
        const providerRes = values[1];
        if (widgetRes.status != 200) {
          throw Error("Error fetching widgets: " + widgetRes.status);
        }
        if (providerRes.status != 200) {
          throw Error(
            "Unable to get notification providers: " + providerRes.status
          );
        }
        setWidgetViewState({
          syncTs: Date.now(),
          widgets: widgetRes.data.widgets,
          providers: providerRes.data,
        });
      })
      .catch((e) => {
        console.error("Unable to sync widget view", e);
      });
  };

  // show advanced mode if there are no acitve widgets so the user can easilly add one
  advancedMode =
    advancedMode ||
    (widgetViewState.syncTs !== undefined &&
      widgetViewState.widgets.length === 0);

  const widgetsWithNewWidget = [newWidget];
  widgetsWithNewWidget.push(...widgetViewState.widgets);
  const widgetsToDisplay = widgetsWithNewWidget.filter(
    (w) => w.notification !== undefined || advancedMode
  );

  let showInfo =
    advancedMode ||
    (widgetsToDisplay.length === 0 && widgetViewState.syncTs !== undefined);
  return (
    <div>
      <Alert hidden={!showInfo} variant="info">
        Widgets aggregate notifications to diplay a single notification from a
        notification provider
      </Alert>
      <Container fluid>
        <Row>
          {widgetsToDisplay.map((w) => (
            <Widget
              key={w._id + widgetViewState.syncTs}
              isNew={w === newWidget}
              advancedMode={advancedMode}
              widget={w}
              providers={widgetViewState.providers}
              requestRefresh={(widgets) => {
                setWidgetViewState({
                  syncTs: Date.now(),
                  widgets: widgets,
                  providers: widgetViewState.providers,
                });
              }}
            />
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default WidgetView;

export const newWidget: IDisplayWidget = {
  _id: "FakeId",
  config: {
    widgetTitle: "Add a new Widget, click settings to customize",
    notificationProvider: {},
    notificationProviderName: "choose a provider name",
    notificationRankType: "createdAt",
    notificationRank: 1,
    showTitle: true,
    displayImageOnly: false,
  },
  notification: undefined,
};

const getTestWidget = (
  notification: INotification | undefined = undefined
): IDisplayWidget => {
  return {
    _id: "id" + Math.random(),
    config: {
      widgetTitle: "test title",
      notificationProvider: {},
      notificationProviderName: "provider name",
      notificationRankType: "createdAt",
      notificationRank: 1,
      showTitle: true,
      displayImageOnly: false,
    },
    notification: notification,
  };
};

const getTestNotification = (): INotification => {
  return {
    title: "Notification title",
    description:
      "This is a test description. This is a test description. This is a test description. This is a test description. This is a test description. This is a test description. This is a test description. This is a test description. This is a test description. This is a test description. This is a test description. This is a test description. This is a test description. This is a test description. ",
    _id: "id",
    dismissed: false,
    type: "type",
    userId: "userId",
  };
};
