import React, { FormEvent, useState } from "react";

type Props = {
  notificationProviderType: INotificationProviderType;
  currentSchedule: IUpdateSchedule;
  onUpdatedSchedule: (update: IUpdateSchedule) => void;
};

const IntervalInput: React.FC<Props> = ({
  notificationProviderType,
  currentSchedule,
  onUpdatedSchedule,
}) => {
  let schedule = currentSchedule as IntervalUpdateSchedule;
  if (schedule.updateIntervalSeconds === undefined) {
    schedule.updateIntervalSeconds =
      notificationProviderType.minUpdateIntervalSeconds;
  }
  let defaultMultiplier = Math.ceil(
    schedule.updateIntervalSeconds /
      notificationProviderType.minUpdateIntervalSeconds
  );
  let updateIntervalDisplay = (function () {
    var updateIntervalSeconds =
      defaultMultiplier * notificationProviderType.minUpdateIntervalSeconds;
    let hours = Math.floor(updateIntervalSeconds / (60 * 60));
    let hoursText = hours > 0 ? "Hours: " + hours + " " : "";
    var minutes = Math.floor(updateIntervalSeconds / 60);
    minutes -= hours * 60;
    let minutesText = minutes > 0 ? "Minutes: " + minutes + " " : "";
    var seconds = updateIntervalSeconds - (hours * 60 * 60 + minutes * 60);
    var secondsText = seconds > 0 ? "Seconds: " + seconds : "";
    return hoursText + minutesText + secondsText;
  })();

  function handleIntervalMultiplierChange(e: FormEvent<HTMLInputElement>) {
    if (e.currentTarget.value.length === 0) return;
    let multiplier = Number.parseInt(e.currentTarget.value);
    if (multiplier > 0) {
      schedule.updateIntervalSeconds =
        notificationProviderType.minUpdateIntervalSeconds * multiplier;
      onUpdatedSchedule(schedule);
    }
  }

  return (
    <div>
      <div className="mb-3">
        <label style={{ color: "black" }}>Update Interval:</label>
        <br />
        <label style={{ color: "black" }}>{updateIntervalDisplay}</label>
      </div>

      <div className="mb-3">
        <label style={{ color: "black" }}>Update Interval Multiplier</label>
        <input
          type="number"
          defaultValue={defaultMultiplier}
          className="form-control"
          onInput={handleIntervalMultiplierChange}
        />
      </div>
    </div>
  );
};

export default IntervalInput;
