import { JSONSchema7 } from "json-schema";
import React, { useEffect, useState } from "react";
import JSONInput from "../notificationProvider/JSONInput";
import { Button, Modal, Spinner } from "react-bootstrap";
import { deleteWidget, getProviders, updateWidgetConfig } from "../../API";
import { newWidget } from "./WidgetView";
import SpinnerButton from "../SpinnerButton";

type Props = {
  showModal: boolean;
  isNew: boolean;
  config: IWidgetConfig;
  providers: INotificationProvider[];
  requestRefresh: (widgets: IDisplayWidget[]) => void;
  requestHide: () => void;
};
const WidgetConfigModal: React.FC<Props> = ({
  showModal,
  isNew,
  config,
  providers,
  requestRefresh,
  requestHide,
}) => {
  const [modalState, setModalState] = useState({
    modifiedConfig: { ...config },
    savingWidget: false,
    deletingWidget: false,
  });

  const handleSave = () => {
    if (
      modalState.deletingWidget ||
      modalState.modifiedConfig.widgetTitle === newWidget.config.widgetTitle
    ) {
      return;
    }
    console.log("Saving updates: " + JSON.stringify(modalState.modifiedConfig));
    // start loading spinner
    setModalState({
      modifiedConfig: modalState.modifiedConfig,
      savingWidget: true,
      deletingWidget: false,
    });

    // make network request
    updateWidgetConfig(modalState.modifiedConfig)
      .then((res) => {
        if (res.status != 200) {
          throw Error("Failed to update widget config: " + res.status);
        }
        console.log("successfully saved widget");
        requestRefresh(res.data.widgets);
      })
      .catch((e) => {
        console.error("Error updating widget config", e);
      });
  };

  const handleDelete = () => {
    if (modalState.savingWidget) {
      return;
    }
    // start loading spinner
    setModalState({
      modifiedConfig: modalState.modifiedConfig,
      savingWidget: false,
      deletingWidget: true,
    });
    deleteWidget(config)
      .then((res) => {
        if (res.status != 200) {
          throw Error("Failed to delete widget: " + res.status);
        }
        requestRefresh(res.data.widgets);
      })
      .catch((e) => {
        console.error("Error deleting widget", e);
      });
  };

  return (
    <div>
      <Modal className="widget-settings" show={showModal} onHide={requestHide}>
        <Modal.Header closeButton>
          <Modal.Title>Modify Widget</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <JSONInput
            schema={WidgetConfigSchema(providers)}
            exampleData="" // TODO
            onDataChanged={(data) => {
              setModalState({
                modifiedConfig: data,
                savingWidget: modalState.savingWidget,
                deletingWidget: modalState.deletingWidget,
              });
            }}
            existingData={JSON.stringify(modalState.modifiedConfig)}
          />
        </Modal.Body>

        <Modal.Footer>
          <SpinnerButton
            varient="danger"
            text="Delete Widget"
            onClick={handleDelete}
            hidden={isNew}
          />
          <Button variant="secondary" onClick={requestHide}>
            Close
          </Button>
          <SpinnerButton
            varient="primary"
            text="Save Changes"
            onClick={handleSave}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WidgetConfigModal;

const WidgetConfigSchema = (providers: INotificationProvider[]) => {
  return {
    type: "object",
    properties: {
      widgetTitle: {
        type: "string",
        description: "Title used to id the widget",
        not: {
          const: newWidget.config.widgetTitle,
        },
      },
      notificationProviderName: {
        type: "string",
        oneOf: providers.map((p) => {
          return { const: p.name, description: "Notification" };
        }),
        description: "title of the provider to aggregate",
      },
      notificationRankType: {
        type: "string",
        description: "TODO support more types. How to rank the notifications.",
        default: "createdAt",
        readOnly: true,
      },
      notificationRank: {
        type: "number",
        description: "Rank of the notification to select, starting at 1",
        minimum: 1,
        default: 1,
      },
      showTitle: {
        type: "boolean",
        description: "Show the title when the wiget is displayed",
        default: true,
      },
      displayImageOnly: {
        type: "boolean",
        description:
          "Show only the image from the notification, if one eixists",
        default: false,
      },
      rankOverride: {
        type: "number",
        description:
          "Override the timestasmp and rank this wiget by number.\nEx. 1 = always first, timestamp used to tie with another 1 ranked wiget",
      },
    },
    required: [
      "widgetTitle",
      "notificationProviderName",
      "showTitle",
      "notificationRankType",
      "notificationRank",
    ],
  } as JSONSchema7;
};
