export default class NewNotificationSocketListener {
  private onNewNotification: (notification: INotification) => void = (n) => {};

  constructor() {
    var host = window.location.origin.replace(/^http/, "ws");
    if (host.includes("localhost:3000")) {
      host = "ws://localhost:8080";
    }

    try {
      let socket = new WebSocket(host);
      // Connection opened
      socket.addEventListener("open", function (event) {
        console.log("The socket is open, listening for notifications");
      });

      let self = this;
      // Listen for messages
      socket.addEventListener("message", function (event) {
        // console.log('Message from server ', event.data);
        let jsonData = JSON.parse(event.data);
        if (jsonData.notifications !== undefined) {
          let notifications = jsonData.notifications as INotification[];
          notifications.forEach((notification) => {
            self.onNewNotification(notification);
          });
        }
      });
    } catch (e: any) {
      console.error("Couldn't open socket connection. " + e);
    }
  }

  setNewNotificationListener(listener: (notification: INotification) => void) {
    this.onNewNotification = listener;
  }
}
