import React, { FormEvent, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { reportIssue } from "../API";

type Props = {};
const ReportIssue: React.FC<Props> = ({}) => {
  let titleRef = React.createRef<HTMLInputElement>();
  let descriptionRef = React.createRef<HTMLTextAreaElement>();
  function submitIssue(e: FormEvent<HTMLFormElement>) {
    if (titleRef.current?.value == null || titleRef.current?.value === "") {
      e.preventDefault();
      return;
    }
    reportIssue({
      title: titleRef.current?.value || "no title",
      description: descriptionRef.current?.value || "no description",
    })
      .then((res) => {
        console.log("Reported issue");
      })
      .catch((err) => {
        console.error("error reporting issue: " + err);
      });
  }
  return (
    <div>
      <h4>Report Issue or Suggestion</h4>
      <Form onSubmit={submitIssue}>
        <Form.Group className="mb-3" controlId="reportIssue.Title">
          <Form.Label>Titile</Form.Label>
          <Form.Control type="text" placeholder="Issue title" ref={titleRef} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="reportIssue.Description">
          <Form.Label>Description</Form.Label>
          <Form.Control as="textarea" rows={3} ref={descriptionRef} />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit Issue or Suggestion
        </Button>
      </Form>
    </div>
  );
};

export default ReportIssue;
