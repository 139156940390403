import React, { FormEvent, useState } from "react";
import NotificationItem from "./notificationItem/NotificationItem";
import NewNotificationSocketListener from "./../NewNotificationSocketListener";
import AwesomeDebouncePromise from "awesome-debounce-promise";

let newNotificationSocketListener = new NewNotificationSocketListener();
type Props = { notifications: INotification[]; showAdvancedOptions: boolean };

const NotificationList: React.FC<Props> = ({
  notifications,
  showAdvancedOptions,
}) => {
  const [filterTerm, setFilterTerm] = useState<string>("");
  let defaultFilterTypeSelection = "Filter By Type";
  const [filterType, setFilterType] = useState<string>(
    defaultFilterTypeSelection
  );

  let setFilterTermDebounced = AwesomeDebouncePromise((term: string) => {
    return Promise.resolve(term);
  }, 400);

  function handleInputChange(e: FormEvent<HTMLInputElement>) {
    setFilterTermDebounced(e.currentTarget.value).then((term) => {
      setFilterTerm(term);
    });
  }
  function handleTypeSelectChange(e: FormEvent<HTMLSelectElement>) {
    setFilterType(e.currentTarget.value);
  }

  // a filtered set of notifications for display
  var displayNotifications: INotification[];
  var notificationsFiltered = true;
  let filterTypeSet = filterType !== defaultFilterTypeSelection;
  if (filterTerm.length === 0 && !filterTypeSet) {
    displayNotifications = notifications;
    // only use display animation in the absence of filters
    notificationsFiltered = false;
  } else {
    let filterExp = new RegExp(filterTerm, "i");
    displayNotifications = notifications.filter((notification) => {
      let typeFilter = !filterTypeSet || notification.type === filterType;
      function textFilter() {
        // keep this as a function so that we can lazily do this filter
        return (
          notification.title.match(filterExp) ||
          (notification.type !== undefined &&
            notification.type.startsWith(filterTerm)) ||
          notification.description.match(new RegExp(filterTerm, "i"))
        );
      }
      return typeFilter && textFilter();
    });
  }
  displayNotifications = displayNotifications.sort(
    (l, r) => -l.createdAt!!.localeCompare(r.createdAt!!)
  );

  var notificationTypes = notifications
    .map((n) => n.type)
    .filter((type, index, self) => {
      return self.indexOf(type) === index;
    })
    .sort((l, r) => {
      return l.localeCompare(r);
    });

  return (
    <div className="notification-section">
      <div
        className="notification-section-header "
        hidden={!showAdvancedOptions}
      >
        <div className="notification-section-header-filters input-group flex-nowrap">
          <div className="col-10">
            <input
              className="form-control"
              type="text"
              placeholder="Filter by text"
              onInput={handleInputChange}
            ></input>
          </div>
          <div className="col">
            <select
              className="form-control"
              name="notification-type-select"
              id="notification-type-select"
              onInput={handleTypeSelectChange}
            >
              <option value={defaultFilterTypeSelection}>
                {defaultFilterTypeSelection}
              </option>
              {notificationTypes.map((type: string, i: number) => (
                <option key={i} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {displayNotifications.map((notification: INotification) => (
        <NotificationItem
          key={notification._id}
          deleteNotification={undefined}
          notification={notification}
          disableAnimation={notificationsFiltered}
        />
      ))}
    </div>
  );
};

export default NotificationList;
