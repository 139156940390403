import React, { FormEvent, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { UpdateScheduleType } from "../../types";
import IntervalInput from "./IntervalInput";
import WeeklyScheduleInput from "./WeeklyScheduleInput";

type Props = {
  notificationProviderType: INotificationProviderType;
  currentSchedule: IUpdateSchedule;
  onUpdatedSchedule: (update: IUpdateSchedule) => void;
};

const NotificationProviderSchedule: React.FC<Props> = ({
  notificationProviderType,
  currentSchedule,
  onUpdatedSchedule,
}) => {
  let input;
  if (
    notificationProviderType.supportedScheduleTypes.length > 0 &&
    !notificationProviderType.supportedScheduleTypes.includes(
      currentSchedule.type
    )
  ) {
    // current type is not supportted, switch to supported type
    currentSchedule.type = notificationProviderType.supportedScheduleTypes[0];
  }

  function changeType(newType: FormEvent<HTMLSelectElement>) {
    currentSchedule.type =
      notificationProviderType.supportedScheduleTypes[
        newType.currentTarget.selectedIndex
      ];
    onUpdatedSchedule(currentSchedule);
  }

  switch (currentSchedule.type) {
    case UpdateScheduleType.Unknown:
      input = <div></div>;
      break;
    case UpdateScheduleType.Interval:
      input = (
        <div>
          <IntervalInput
            notificationProviderType={notificationProviderType}
            currentSchedule={currentSchedule}
            onUpdatedSchedule={onUpdatedSchedule}
          />
        </div>
      );
      break;
    case UpdateScheduleType.Week:
      input = (
        <div>
          <WeeklyScheduleInput
            currentSchedule={currentSchedule}
            onUpdatedSchedule={onUpdatedSchedule}
          />
        </div>
      );
      break;
    default:
      throw new Error("Not implemented update schedule type");
  }
  return (
    <div>
      <span>
        Update schedule type:{" "}
        <Form.Select
          defaultValue={UpdateScheduleType[currentSchedule.type]}
          onChange={changeType}
          aria-label="Select type of update schedule"
        >
          {notificationProviderType.supportedScheduleTypes.map((v, i) => (
            <option key={i}>{UpdateScheduleType[v]}</option>
          ))}
        </Form.Select>
      </span>
      <br />
      {input}
    </div>
  );
};

export default NotificationProviderSchedule;
