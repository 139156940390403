import React from "react";
import { IChangeEvent } from "@rjsf/core";
import Form from "@rjsf/bootstrap-4";

type Props = {
  schema: any;
  existingData: string;
  exampleData: string;
  onDataChanged: (data: any) => void;
};

const JSONInput: React.FC<Props> = ({
  schema,
  existingData,
  exampleData,
  onDataChanged,
}) => {
  function onChange(e: IChangeEvent<any>) {
    // console.log("Form data" + JSON.stringify(e.formData));
    if (e.errors.length == 0) onDataChanged(e.formData);
    else {
      console.log("Errors seen, not updating");
    }
  }
  function onError() {}
  // console.log("Schema: " + JSON.stringify(schema));
  let formData = {};
  try {
    formData = JSON.parse(existingData);
  } catch (e) {
    try {
      formData = JSON.parse(exampleData);
    } catch (e) {}
  }
  return (
    <div>
      <Form
        schema={schema}
        formData={formData}
        onChange={onChange}
        onError={onError}
        children={true} // hide submit button
        liveValidate
      />
    </div>
  );
};

export default JSONInput;
