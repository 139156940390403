import axios, { AxiosResponse } from "axios";
import Widget from "./components/widgets/Widget";

var baseUrl: string = window.location.origin;
// replace the port if we're using the dev server
if (baseUrl.includes("localhost:3000")) {
  baseUrl = baseUrl.replace("3000", "8080");
}
let baseUrlWitoutApi = baseUrl;
baseUrl += "/api/v1";

axios.defaults.withCredentials = true;

export const login = async (
  username: string,
  password: string
): Promise<AxiosResponse<UserManagementResponse>> => {
  try {
    let result = await axios.post(`${baseUrlWitoutApi}/login`, {
      username: username,
      password: password,
    });
    console.log("Login result: " + JSON.stringify(result.data));
    return result;
  } catch (error: any) {
    throw Error(error);
  }
};

export const logout = async (): Promise<
  AxiosResponse<UserManagementResponse>
> => {
  try {
    let result = await axios.post(`${baseUrlWitoutApi}/logout`, {});
    console.log("Logout result: " + JSON.stringify(result.data));
    return result;
  } catch (error: any) {
    throw Error(error);
  }
};

export const register = async (
  user: any
): Promise<AxiosResponse<UserManagementResponse>> => {
  try {
    console.log("url: " + baseUrlWitoutApi);
    let result = await axios.post(`${baseUrlWitoutApi}/register`, user);
    return result;
  } catch (error: any) {
    throw Error(error);
  }
};

export const checkAuth = async (): Promise<
  AxiosResponse<UserManagementResponse>
> => {
  try {
    return await axios.get(`${baseUrlWitoutApi}/check-auth`);
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getNotifications = async (): Promise<
  AxiosResponse<ApiDataType>
> => {
  try {
    const notifications: AxiosResponse<ApiDataType> = await axios.get(
      baseUrl + "/notifications"
    );
    return notifications;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const dismissNotification = async (
  _id: string
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const deletedNotification: AxiosResponse<ApiDataType> = await axios.delete(
      `${baseUrl}/notifications/dismiss/${_id}`
    );
    return deletedNotification;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const clearNotifications = async (): Promise<
  AxiosResponse<ApiDataType>
> => {
  try {
    const remaining: AxiosResponse<ApiDataType> = await axios.delete(
      `${baseUrl}/notifications/dismiss`
    );
    return remaining;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const hardDeleteAllNotifications = async (): Promise<
  AxiosResponse<ApiDataType>
> => {
  try {
    const remaining: AxiosResponse<ApiDataType> = await axios.delete(
      `${baseUrl}/notifications/delete`
    );
    return remaining;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const resurrectNotifications = async (): Promise<
  AxiosResponse<ApiDataType>
> => {
  try {
    const remaining: AxiosResponse<ApiDataType> = await axios.post(
      `${baseUrl}/notifications/resurrect`
    );
    return remaining;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const setPushSubscription = async (
  subscription: any
): Promise<AxiosResponse<any>> => {
  try {
    let result = await axios.post(
      `${baseUrl}/settings/set-push-subscription`,
      subscription
    );
    return result;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getSettings = async (): Promise<
  AxiosResponse<{ settings: ISettings }>
> => {
  try {
    let result = await axios.get(`${baseUrl}/settings`);
    return result;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getAvalibleProviderTypes = async (): Promise<
  AxiosResponse<INotificationProviderType[]>
> => {
  try {
    let result = await axios.get(`${baseUrl}/availableProviders`);
    return result;
  } catch (err: any) {
    console.log("Oh no, an error: " + err);
    throw new Error(err);
  }
};

export const getProviders = async (): Promise<
  AxiosResponse<INotificationProvider[]>
> => {
  try {
    let result = await axios.get(`${baseUrl}/providers`);
    return result;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const addOrUpdateProvider = async (
  provider: INotificationProvider
): Promise<AxiosResponse<INotificationProvider[]>> => {
  try {
    return await axios.post(`${baseUrl}/providers/update`, provider);
  } catch (err: any) {
    throw new Error(err);
  }
};

export const followPublicUser = async (
  username: string
): Promise<AxiosResponse<void>> => {
  try {
    return await axios.post(`${baseUrl}/providers/followPublicUser`, {
      usernameToFollow: username,
    });
  } catch (err: any) {
    throw new Error(err);
  }
};

export const deleteProvider = async (
  provider: INotificationProvider
): Promise<AxiosResponse<INotificationProvider[]>> => {
  try {
    return await axios.post(`${baseUrl}/providers/delete`, provider);
  } catch (err: any) {
    throw new Error(err);
  }
};

export const forceRunProvider = async (
  providerName: string
): Promise<AxiosResponse<INotificationProvider[]>> => {
  try {
    return await axios.post(`${baseUrl}/providers/forceRun`, {
      name: providerName,
    });
  } catch (err: any) {
    throw new Error(err);
  }
};

export const fetchPublicUser = async (
  username: string
): Promise<AxiosResponse<PublicUserFetchResponse>> => {
  try {
    const response: AxiosResponse<PublicUserFetchResponse> = await axios.get(
      baseUrl + "/publicUser/" + username
    );
    return response;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const fetchPublicUsers = async (): Promise<
  AxiosResponse<PublicUsersResponse>
> => {
  try {
    const response: AxiosResponse<PublicUsersResponse> = await axios.get(
      baseUrl + "/publicUser"
    );
    return response;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const reportIssue = async (
  issue: IIssueReport
): Promise<AxiosResponse<void>> => {
  try {
    const response = await axios.post(baseUrl + "/report", issue);
    return response;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const fetchWidgets = async (): Promise<
  AxiosResponse<{ widgets: IDisplayWidget[] }>
> => {
  return axios.get(baseUrl + "/widgets");
};

export const updateWidgetConfig = async (
  widgetConfig: IWidgetConfig
): Promise<AxiosResponse<{ widgets: IDisplayWidget[] }>> => {
  return axios.post(baseUrl + "/widgets/update", widgetConfig);
};

export const deleteWidget = async (
  widgetConfig: IWidgetConfig
): Promise<AxiosResponse<{ widgets: IDisplayWidget[] }>> => {
  return axios.post(baseUrl + "/widgets/delete", widgetConfig);
};
