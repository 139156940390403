import React, { useEffect, useState } from "react";
import {
  getSettings,
  hardDeleteAllNotifications,
  logout,
  setPushSubscription,
} from "../API";
import { setupPushNotifications } from "../pushNotifications/PushNotification";
import Container from "react-bootstrap/Container";
import ReportIssue from "./ReportIssue";

type Props = {
  user: User;
  onLogout: () => void;
};

const SettingsPage: React.FC<Props> = ({ user, onLogout }) => {
  const [notificationsEnabled, setEnableNotifications] =
    useState<Boolean>(false);
  useEffect(() => {
    getSettings()
      .then((response) => {
        let settings = response.data.settings;
        if (settings.pushSubscription && settings.pushSubscription.endpoint) {
          setEnableNotifications(true);
        }
      })
      .catch((err) => {
        console.error("Error getting settings: " + err);
      });
  });

  const notifySetup = (): void => {
    if (notificationsEnabled) {
      setPushSubscription({}).then((_) => {
        setEnableNotifications(false);
      });
    } else {
      setupPushNotifications();
      setEnableNotifications(true);
    }
  };
  const forceDeleteNotifications = (): void => {
    hardDeleteAllNotifications()
      .then((res) => {
        console.log("Hard Deleted all notifications");
      })
      .catch((err) =>
        console.error("Error deleting all notifications: " + err)
      );
  };
  const logoutClicked = (): void => {
    logout()
      .then((res) => {
        onLogout();
      })
      .catch((err) => {
        console.error("Error logoging out");
        onLogout();
      });
  };

  return (
    <Container className="settings-page">
      <h5 className="settings-title">
        Settings | Logged in with '{user.username}
      </h5>
      <div>
        <div className="mb-3">
          <label htmlFor="enable-notification-button" className="form-label">
            Click to enable or disable browser notifications
          </label>
          <button
            id="enable-notification-button"
            className="btn btn-primary form-control"
            onClick={() => notifySetup()}
          >
            {notificationsEnabled
              ? "Disable Notifications"
              : "Enable Notifications"}
          </button>
        </div>
        <div className="mb-3">
          <ReportIssue />
        </div>
        <div className="mb-3">
          <label
            htmlFor="force-delete-all-notifications"
            className="form-label"
          >
            Force delete all stored notifications. Currently they are hidden for
            a period of time to prevent duplicates. This can cause duplicate
            notifications to appear.
          </label>
          <button
            id="force-delete-all-notifications"
            className="btn btn-primary form-control"
            onClick={() => forceDeleteNotifications()}
          >
            Force Wipe all Notifications
          </button>
        </div>
        <div className="mb-3">
          <button
            className="btn btn-danger"
            data-bs-dismiss="modal"
            onClick={() => logoutClicked()}
          >
            Logout of {user.username}
          </button>
        </div>
      </div>
    </Container>
  );
};

export default SettingsPage;
