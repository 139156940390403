import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Container from "react-bootstrap/esm/Container";
import { useParams } from "react-router";
import { fetchPublicUser, followPublicUser, getProviders } from "../API";
import Alert from "react-bootstrap/Alert";
import NotificationList from "./NotificationList";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

type State = {
  userData?: PublicUserFetchResponse;
  initialLoadComplete: Boolean;
  isFollowing?: Boolean;
};

type Params = {
  isLoggedIn: Boolean;
  showAdvancedOptions: boolean;
};

const PublicNotificationList: React.FC<Params> = ({
  isLoggedIn,
  showAdvancedOptions,
}) => {
  let { username } = useParams() as any;

  let [state, setState] = useState<State>({
    userData: undefined,
    initialLoadComplete: false,
    isFollowing: undefined,
  });

  useEffect(() => {
    fetchPublicUser(username)
      .then((res) => {
        console.log("Public user fetch success");
        setState({ userData: res.data, initialLoadComplete: true });
        getProviders()
          .then((res2) => {
            console.log("Determing if we are subscribed to this user");
            setState({
              userData: res.data,
              initialLoadComplete: true,
              isFollowing: res2.data.some((provider) => {
                return (
                  provider.providerType ===
                    "Public User Notification Provider" &&
                  provider.name.includes(username)
                );
              }),
            });
          })
          .catch((err) => {
            console.error("Couldn't fetch providers: " + err);
          });
      })
      .catch((err) => {
        console.error("Error loading public user: " + err);
        setState({
          userData: undefined,
          initialLoadComplete: true,
        });
      });
    if (isLoggedIn) {
    }
  }, []);

  let body = <div>Loading please wait...</div>;

  function subscribeToNotifications() {
    followPublicUser(username)
      .then((res) => {
        console.log("Successfully registered to public user");
        setState({
          userData: state.userData,
          initialLoadComplete: true,
          isFollowing: true,
        });
      })
      .catch((err) => {
        console.error("Error follinging public user: " + err);
      });
  }

  let followPublicUserView;
  if (isLoggedIn && state.isFollowing === false) {
    followPublicUserView = (
      <button className="btn btn-primary" onClick={subscribeToNotifications}>
        Subscribe to these notifications
      </button>
    );
  } else if (isLoggedIn && state.isFollowing === true) {
    followPublicUserView = (
      <div>
        You have subscribed to notifications from this user. Manage in&nbsp;
        <Link to="/notification-providers">Notification Providers</Link>
      </div>
    );
  } else if (!isLoggedIn) {
    followPublicUserView = (
      <div>Login or Register to subscribe to these notifications</div>
    );
  }

  if (state.initialLoadComplete && state.userData) {
    body = (
      <div>
        <Card className="public-user-card">
          <Card.Body>{state.userData!!.userDescription}</Card.Body>
        </Card>
        {followPublicUserView}
        <hr />
        <NotificationList
          notifications={state.userData.notifications}
          showAdvancedOptions={showAdvancedOptions}
        />
      </div>
    );
  } else if (state.initialLoadComplete) {
    body = (
      <Alert variant="danger">
        <Alert.Heading>
          Unfortunatly, the public user "{username}" could not be found.
        </Alert.Heading>
        <p>
          This user may have been deleted or marked as private. Please contact
          the person who gave you this link for more details.
        </p>
      </Alert>
    );
  }

  return <Container>{body}</Container>;
};

export default PublicNotificationList;
