import React from "react";

type Props = {
  body: string;
};

const NotificationBodyFormat: React.FC<Props> = ({ body }) => {
  const FORMAT_INDICATOR = "<advanced_format>";
  if (body.indexOf(FORMAT_INDICATOR) !== 0) {
    throw new Error("Missing format indicator");
  }
  // remove indicator
  body = body.substring(FORMAT_INDICATOR.length);
  const bodyObj = JSON.parse(body) as NotificationBodyFormat;

  return (
    <div className="card-body">
      {bodyObj.sections.map((section: NotificationBodySectionFormat) => (
        <div className="notification-descrtiption">
          {section.header && <h4>{section.header}</h4>}
          {section.text && <p>{section.text}</p>}
          {section.json && <pre>{JSON.stringify(section.json, null, 2)}</pre>}
          {section.link && (
            <a
              style={{ color: "black" }}
              href={`${section.link!!.url}`}
              target="_blank"
              rel="noreferrer"
            >
              {section.link!!.title}
            </a>
          )}
          {section.imageUrl && (
            <img
              className="notification-image"
              src={section.imageUrl!!}
              alt=""
            />
          )}
        </div>
      ))}
    </div>
  );
};
export default NotificationBodyFormat;
