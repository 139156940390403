import React, { FormEvent, useEffect, useState } from "react";
import NotificationItem from "./notificationItem/NotificationItem";
import {
  getNotifications,
  dismissNotification,
  clearNotifications,
} from "./../API";
import NewNotificationSocketListener from "./../NewNotificationSocketListener";
import { resurrectNotifications } from "../API";
import AwesomeDebouncePromise from "awesome-debounce-promise";

let newNotificationSocketListener = new NewNotificationSocketListener();
type Props = { user: User; showAdvancedOptions: boolean };

const PrivateNotificationList: React.FC<Props> = ({
  user,
  showAdvancedOptions,
}) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [filterTerm, setFilterTerm] = useState<string>("");
  let defaultFilterTypeSelection = "Filter By Type";
  const [filterType, setFilterType] = useState<string>(
    defaultFilterTypeSelection
  );

  useEffect(() => {
    fetchNotifications();
  }, []);

  newNotificationSocketListener.setNewNotificationListener((notification) => {
    if (notification.userId !== user._id) return;
    var isDupe = false;
    notifications.forEach((n) => {
      if (n.title === notification.title) {
        isDupe = true;
      }
    });
    if (!isDupe) {
      console.log("Add new notification");
      var newList = [notification].concat(notifications);
      console.log("New list " + newList.length.toString());
      setNotifications(newList);
    }
  });

  const fetchNotifications = (): void => {
    getNotifications()
      .then((data) => {
        setNotifications(data.data.notifications);
      })
      .catch((err: Error) => console.log(err));
  };

  const handleDismissNotification = (_id: string): void => {
    // remove from the list then tell the server to update state
    setNotifications(
      notifications.filter((n) => {
        return n._id !== _id;
      })
    );
    dismissNotification(_id)
      .then(({ status, data }) => {
        if (status !== 200) {
          throw new Error("Error! Notification not deleted");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleResurrectNotifications = (): void => {
    resurrectNotifications()
      .then(({ status, data }) => {
        if (status !== 200) {
          throw new Error("Error! Couldn't resurrect notifications");
        }
        setNotifications(data.notifications);
      })
      .catch((err) => console.log(err));
  };

  let setFilterTermDebounced = AwesomeDebouncePromise((term: string) => {
    return Promise.resolve(term);
  }, 400);

  function handleInputChange(e: FormEvent<HTMLInputElement>) {
    setFilterTermDebounced(e.currentTarget.value).then((term) => {
      setFilterTerm(term);
    });
  }
  function handleTypeSelectChange(e: FormEvent<HTMLSelectElement>) {
    setFilterType(e.currentTarget.value);
  }

  // a filtered set of notifications for display
  var displayNotifications: INotification[];
  var notificationsFiltered = true;
  let filterTypeSet = filterType !== defaultFilterTypeSelection;
  if (filterTerm.length === 0 && !filterTypeSet) {
    displayNotifications = notifications;
    // only use display animation in the absence of filters
    notificationsFiltered = false;
  } else {
    let filterExp = new RegExp(filterTerm, "i");
    displayNotifications = notifications.filter((notification) => {
      let typeFilter = !filterTypeSet || notification.type === filterType;
      function textFilter() {
        // keep this as a function so that we can lazily do this filter
        return (
          notification.title.match(filterExp) ||
          (notification.type !== undefined &&
            notification.type.startsWith(filterTerm)) ||
          notification.description.match(new RegExp(filterTerm, "i"))
        );
      }
      return typeFilter && textFilter();
    });
  }
  displayNotifications = displayNotifications.sort((l, r) => {
    return -l.updatedAt!!.localeCompare(r.updatedAt!!);
  });

  var notificationTypes = notifications
    .map((n) => n.type)
    .filter((type, index, self) => {
      return self.indexOf(type) === index;
    })
    .sort((l, r) => {
      return l.localeCompare(r);
    });

  const handleClearNotification = (): void => {
    if (notificationsFiltered) {
      let filteredIds = new Set(displayNotifications.map((n) => n._id));
      let newNotifications = notifications.filter((n) => {
        return !filteredIds.has(n._id);
      });
      setNotifications(newNotifications);
      let promises = displayNotifications.map((n) =>
        dismissNotification(n._id).catch((err) => {
          console.error("Failed to dismiss notification by id: " + err);
        })
      );
      Promise.all(promises)
        .catch((err) => {
          console.error("Error clearing notifications: " + err);
          return [];
        })
        .then((data) => {});
    } else {
      clearNotifications()
        .then(({ status, data }) => {
          if (status !== 200) {
            throw new Error("Error! Couldn't clear notifications");
          }
          setNotifications(data.notifications);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="notification-section">
      <div
        className="notification-section-header "
        hidden={!showAdvancedOptions}
      >
        <div className="notification-section-header-filters input-group flex-nowrap">
          <div className="col-10">
            <input
              className="form-control"
              type="text"
              placeholder="Filter by text"
              onInput={handleInputChange}
            ></input>
          </div>
          <div className="col">
            <select
              className="form-control"
              name="notification-type-select"
              id="notification-type-select"
              onInput={handleTypeSelectChange}
            >
              <option value={defaultFilterTypeSelection}>
                {defaultFilterTypeSelection}
              </option>
              {notificationTypes.map((type: string) => (
                <option value={type}>{type}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="notification-section-header-modifiers">
          <button
            className="btn btn-danger"
            onClick={() => handleClearNotification()}
          >
            {notificationsFiltered ? "Clear Filtered" : "Clear All"}
          </button>
          <button
            className="btn btn-success"
            onClick={() => handleResurrectNotifications()}
          >
            Resurrect Notifications
          </button>
        </div>
        <p>Displayed Count: {displayNotifications.length}</p>
      </div>
      {displayNotifications.map((notification: INotification) => (
        <NotificationItem
          key={notification._id}
          deleteNotification={handleDismissNotification}
          notification={notification}
          disableAnimation={notificationsFiltered}
        />
      ))}
    </div>
  );
};

export default PrivateNotificationList;
