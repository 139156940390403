import React, { FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import {
  addOrUpdateProvider,
  deleteProvider,
  forceRunProvider,
} from "../../API";
import { UpdateScheduleType } from "../../types";
import SpinnerButton from "../SpinnerButton";
import JSONInput from "./JSONInput";
import NotificationProviderSchedule from "./NotificationProviderSchedule";
import { useNavigate } from "react-router-dom";

type Props = {
  notificationProviderType: INotificationProviderType;
  // this is only needed to view or update an existing provider
  notificationProvider?: INotificationProvider;
};

const NotificationProvider: React.FC<Props> = ({
  notificationProviderType,
  notificationProvider,
}) => {
  const defaultName = "Choose provider name";
  let originalProvider = notificationProvider || {
    name: defaultName,
    providerType: notificationProviderType.providerTypeName,
    _id: "",
    enabled: true,
    updateIntervalSeconds: notificationProviderType.minUpdateIntervalSeconds,
    pushNotificationEnabled: true,
    params: notificationProviderType.exampleParams,
    updateSchedule: { type: UpdateScheduleType.Unknown },
  };
  let isPublicUserProvider =
    originalProvider.providerType === "Public User Notification Provider";

  let [provider, setProvider] =
    useState<INotificationProvider>(originalProvider);

  let navigate = useNavigate();
  // Make sure values are initalized
  let addingNew = notificationProvider === undefined;

  function handleNameChange(e: FormEvent<HTMLInputElement>) {
    provider.name = e.currentTarget.value;
    setProvider(provider);
  }

  function handleEnabledChange(e: FormEvent<HTMLInputElement>) {
    provider.enabled = e.currentTarget.checked;
    setProvider(provider);
  }

  function handlePushEnabledChange(e: FormEvent<HTMLInputElement>) {
    provider.pushNotificationEnabled = e.currentTarget.checked;
    setProvider(provider);
  }
  function handleDelete() {
    deleteProvider(provider)
      .then((res) => {
        console.log(
          "deleting notification provider: " + JSON.stringify(res.data)
        );
        navigate(0);
      })
      .catch((err) => {
        console.error("Error deleting notification provider");
      });
  }

  function forceRunNotificationProvider(onComplete: () => void) {
    forceRunProvider(provider.name)
      .then((res) => {
        console.log("Force run provider with name: " + provider.name);
        console.log(JSON.stringify(res.data));
        onComplete();
      })
      .catch((err) => {
        console.error("Error force fetching notifications");
        onComplete();
      });
  }

  function submit(onComplete: () => void) {
    addOrUpdateProvider(provider)
      .then((res) => {
        console.log("Updated providers: " + JSON.stringify(res.data));
        onComplete();
      })
      .catch((err) => {
        console.error("Error updating provider: " + err);
        navigate(0);
      });
  }

  function handleParamsChanges(e: any) {
    provider.params = JSON.stringify(e);
    setProvider(provider);
  }

  let followingUserLink: string | undefined = undefined;
  if (isPublicUserProvider) {
    followingUserLink =
      "/public/" + provider.name.substring("Provider for ".length);
  }

  function onUpdatedSchedule(newSchedule: IUpdateSchedule) {
    provider = { ...provider };
    provider.updateSchedule = newSchedule;
    setProvider(provider);
  }
  let schedule = (
    <NotificationProviderSchedule
      notificationProviderType={notificationProviderType}
      currentSchedule={provider.updateSchedule}
      onUpdatedSchedule={onUpdatedSchedule}
    />
  );

  let actual = (
    <div className="notification-provider">
      <div>
        <div className="mb-3">
          <h5>{provider.providerType}</h5>
        </div>
        <div>
          <p>{notificationProviderType.description}</p>
        </div>
        <div className="mb-3">
          <input
            type="text"
            defaultValue={provider.name}
            readOnly={!addingNew}
            className="form-control"
            onInput={handleNameChange}
          />
        </div>
        {schedule}
        {!isPublicUserProvider && (
          <div className="mb-3">
            <h5>Parameters (* = required)</h5>
            <hr />
            <JSONInput
              schema={JSON.parse(notificationProviderType.paramsSchema || "")}
              existingData={provider.params || "{}"}
              exampleData={notificationProviderType.exampleParams}
              onDataChanged={handleParamsChanges}
            ></JSONInput>
            <hr />
          </div>
        )}

        <div className="mb-3 form-check">
          <input
            type="checkbox"
            defaultChecked={originalProvider.enabled}
            className="form-check-input"
            onChange={handleEnabledChange}
          />
          <label
            style={{ color: "black" }}
            className="form-check-label"
            htmlFor="notificationProviderEnabled"
          >
            Enabled
          </label>
        </div>
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            defaultChecked={originalProvider.pushNotificationEnabled}
            className="form-check-input"
            onChange={handlePushEnabledChange}
          />
          <label
            style={{ color: "black" }}
            className="form-check-label"
            htmlFor="notificationProviderPushEnabled"
          >
            Push Notifications Enabled
          </label>
        </div>
        <SpinnerButton
          varient="success"
          text={addingNew ? "Create" : "Update"}
          onClick={submit}
        />
        <SpinnerButton
          varient="danger"
          text="Delete Provider"
          onClick={handleDelete}
        />
        {!isPublicUserProvider && (
          <SpinnerButton
            varient="info"
            text="Force Run Provider"
            onClick={forceRunNotificationProvider}
          />
        )}
        {followingUserLink && (
          <Link className="btn btn-primary" to={followingUserLink}>
            See public user
          </Link>
        )}
      </div>
      <br />
    </div>
  );
  return actual;
};

export default NotificationProvider;
