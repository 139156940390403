import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";

type Props = {
  varient: string;
  text: string;
  onClick: (onComplete: () => void) => void;
} & Partial<{
  hidden: boolean | undefined;
}>;
const SpinnerButton: React.FC<Props> = ({ varient, text, onClick, hidden }) => {
  const [spinnerEnabled, setSpinnerEnabled] = useState<boolean>(false);

  function buttonClicked() {
    setSpinnerEnabled(true);
    onClick(() => {
      setSpinnerEnabled(false);
    });
  }

  return (
    <Button variant={varient} onClick={buttonClicked} hidden={hidden === true}>
      {text}
      <Spinner
        hidden={!spinnerEnabled}
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    </Button>
  );
};

export default SpinnerButton;
