import React, { FormEvent, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import { login, register } from "../API";

type Props = {
  onAuthenticated: (user: User) => void;
};

type State = {
  user: User;
  registration: boolean;
  showFailure: boolean;
};
const LoginRegister: React.FC<Props> = ({ onAuthenticated }) => {
  var [loginState, setLoginState] = useState<State>({
    user: {
      _id: "",
      username: "Enter your username",
      password: "",
      email: "",
      isPublic: false,
      description: ""
    },
    registration: false,
    showFailure: false,
  });

  function onUsernameChange(e: FormEvent<HTMLInputElement>) {
    loginState.user.username = e.currentTarget.value;
    setLoginState(loginState);
  }
  function onEmailChange(e: FormEvent<HTMLInputElement>) {
    loginState.user.email = e.currentTarget.value;
    setLoginState(loginState);
  }
  function onPasswordChange(e: FormEvent<HTMLInputElement>) {
    loginState.user.password = e.currentTarget.value;
    setLoginState(loginState);
  }
  function onIsPublicChange(e: FormEvent<HTMLInputElement>) {
    loginState.user.isPublic = e.currentTarget.checked;
    setLoginState(loginState);
  }
  function onDescriptionChange(e: FormEvent<HTMLInputElement>) {
    loginState.user.description = e.currentTarget.value;
    setLoginState(loginState);
  }

  function onSubmitForm(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (loginState.registration) {
      console.log("regisiter: " + JSON.stringify(loginState.user));
      register(loginState.user)
        .then((res) => {
          console.log("registration success");
          onAuthenticated(res.data.user!!);
        })
        .catch((err) => {
          console.log("Error registering account");
          setLoginState({
            user: loginState.user,
            registration: loginState.registration,
            showFailure: true,
          });
        });
    } else {
      login(loginState.user.username, loginState.user.password)
        .then((res) => {
          onAuthenticated(res.data.user!!);
        })
        .catch((err) => {
          console.log("Error logging in");
          setLoginState({
            user: loginState.user,
            registration: loginState.registration,
            showFailure: true,
          });
        });
    }
  }
  function onRegistration(e: FormEvent<HTMLButtonElement>) {
    loginState.registration = !loginState.registration;
    setLoginState({ ...loginState });
  }

  var emailInput = <div></div>;
  var isPublicInput = <div></div>;
  var descriptionInput = <div></div>;
  if (loginState.registration) {
    emailInput = (
      <div className="mb-3">
        <label htmlFor="loginEmailInput" className="form-label">
          Email address
        </label>
        <input
          type="email"
          className="form-control"
          id="loginEmailInput"
          aria-describedby="emailHelp"
          onInput={onEmailChange}
        />
        <div id="emailHelp" className="form-text">
          We'll never share your email with anyone else.
        </div>
      </div>
    );
    isPublicInput = (
        <div className="mb-3">
        <label htmlFor="isPublicInput" className="form-label">
          Public User? 
        </label>
        <input
          type="checkbox"
          className="form-check-input"
          id="isPublicInput"
          aria-describedby="isPublicHelp"
          onInput={onIsPublicChange}
        />
        <div id="isPublicHelp" className="form-text">
          Allow a link to your notifications without the ability to modify them.
        </div>
      </div>
    )
    descriptionInput = (
        <div className="mb-3">
        <label htmlFor="descriptionInput" className="form-label">
          Description of the user
        </label>
        <input
          type="textarea"
          className="form-control"
          id="descriptionInput"
          onInput={onDescriptionChange}
        />
      </div>
      )
  }

  var invalidUserWarning = <div></div>;
  if (loginState.showFailure) {
    invalidUserWarning = (
      <div
        className="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
        <strong>Oops!</strong> Looks like your username or password is
        incorrect, please try again.
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    );
  }

  let actual = (
    <Container>
      {invalidUserWarning}
      <button className="btn btn-primary" onClick={onRegistration}>
        {loginState.registration
          ? "Login to existing account?"
          : "Register new Account?"}
      </button>
      <form onSubmit={onSubmitForm}>
        <div className="mb-3">
          <label htmlFor="loginUsernameInput" className="form-label">
            Username
          </label>
          <input
            type="text"
            className="form-control"
            id="loginUsernameInput"
            aria-describedby="emailHelp"
            onInput={onUsernameChange}
          />
          <div id="emailHelp" className="form-text">
            Pick a custom username.
          </div>
        </div>
        {emailInput}
        <div className="mb-3">
          <label htmlFor="loginPasswordInput" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="loginPasswordInput"
            onInput={onPasswordChange}
          />
        </div>
        {isPublicInput}
        {descriptionInput}
        <button type="submit" className="btn btn-primary">
          {loginState.registration ? "Register" : "Login"}
        </button>
      </form>
    </Container>
  );
  return actual;
};

export default LoginRegister;
