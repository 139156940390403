import React, { FormEvent, useEffect, useState } from "react";
import {
  getAvalibleProviderTypes,
  getProviders,
  addOrUpdateProvider,
  deleteProvider,
  forceRunProvider,
} from "../API";
import NotificationProvider from "./notificationProvider/NotificationProvider";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import { UpdateScheduleType } from "../types";

type Props = {};

type NotificationProviderListState = {
  availableProviders: INotificationProviderType[];
  providers: INotificationProvider[];
  typeToCreate: string;
};

const NotificationProviderList: React.FC<Props> = ({}) => {
  const defaultCreateTypeName = "Select a provider type to create";
  const [state, setState] = useState<NotificationProviderListState>({
    availableProviders: [],
    providers: [],
    typeToCreate: defaultCreateTypeName,
  });
  useEffect(() => {
    async function fetch() {
      try {
        const availableProviders = await getAvalibleProviderTypes();
        const providers = await getProviders();
        setState({
          availableProviders: availableProviders.data,
          providers: providers.data,
          typeToCreate: defaultCreateTypeName,
        });
      } catch (err) {
        console.error("Error fetching provider types: " + err);
      }
    }
    fetch();
  }, []);

  function handleCreateNewType(e: FormEvent<HTMLSelectElement>) {
    console.log("Creating new provider of type " + e.currentTarget.value);
    setState({
      availableProviders: state.availableProviders,
      providers: state.providers,
      typeToCreate: e.currentTarget.value,
    });
  }

  let creatableTypes = state.availableProviders.map(
    (type) => type.providerTypeName
  );

  let createProviders = state.availableProviders.filter((provider) => {
    return provider.providerTypeName === state.typeToCreate;
  });
  function getTypeForName(
    typeName: string
  ): INotificationProviderType | undefined {
    let match = state.availableProviders.filter((provider) => {
      return provider.providerTypeName === typeName;
    });
    if (match.length > 0) {
      return match[0];
    }
  }

  let providersByType = new Map<
    string,
    { p: INotificationProvider; t: INotificationProviderType }[]
  >();
  state.providers.forEach((provider) => {
    let providerType = getTypeForName(provider.providerType);
    if (provider.providerType === "Public User Notification Provider") {
      providerType = {
        providerTypeName: "Public User notification Provider",
        exampleParams: "",
        description: "Provides notifications for a public user",
        minUpdateIntervalSeconds: 0,
        supportedScheduleTypes: [UpdateScheduleType.Unknown],
      } as INotificationProviderType;
    }
    if (providerType === undefined) {
      console.error("Not type for provider: " + provider.providerType);
      return;
    }
    let typeName = provider.providerType;
    let item = { p: provider, t: providerType!! };
    if (providersByType.has(typeName)) {
      providersByType.get(typeName)?.push(item);
    } else {
      let newList = [item];
      providersByType.set(typeName, newList);
    }
  });

  let actual = (
    <Container className="notification-provider-list">
      <h3 className="settings-title" id="notificationProviderModalLabel">
        Notification Providers
      </h3>
      <select
        className="form-control"
        name="notification-type-select"
        id="notification-type-select"
        onInput={handleCreateNewType}
      >
        <option value={defaultCreateTypeName}>{defaultCreateTypeName}</option>
        {creatableTypes.map((type: string, i: number) => (
          <option key={i} value={type}>
            {type}
          </option>
        ))}
      </select>

      <div>
        {createProviders.map((providerType) => (
          <div className="card">
            <NotificationProvider
              key={providerType.providerTypeName}
              notificationProviderType={providerType}
              notificationProvider={undefined}
            />
          </div>
        ))}
        <br />
        <h4>Your Providers</h4>
        <Accordion>
          {Array.from(providersByType.keys())
            .sort()
            .map((key, idx) => (
              <Accordion.Item
                key={idx}
                className="notification-provider-list-accordion"
                eventKey={idx.toString()}
              >
                <Accordion.Header>{key}</Accordion.Header>
                <Accordion.Body>
                  <p>{getTypeForName(key)?.description}</p>
                  <Accordion>
                    {providersByType.get(key)!!.map((val, idx2) => (
                      <Accordion.Item
                        key={idx2}
                        eventKey={idx.toString() + ":" + idx2.toString()}
                      >
                        <Accordion.Header>{val.p.name}</Accordion.Header>
                        <Accordion.Body>
                          <NotificationProvider
                            key={val.p.name + val.p.providerType}
                            notificationProvider={val.p}
                            notificationProviderType={val.t!!}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            ))}
        </Accordion>
      </div>
    </Container>
  );
  return actual;
};

export default NotificationProviderList;
