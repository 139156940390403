import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import WidgetConfigModal from "./WidgetConfigModal";
import Image from "react-bootstrap/Image";
import { deleteWidget, updateWidgetConfig } from "../../API";
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import { newWidget } from "./WidgetView";
import NotificationBodyFormat from "../notificationItem/NotificationBodyFormat";
import SpinnerButton from "../SpinnerButton";

type Props = {
  isNew: boolean;
  widget: IDisplayWidget;
  advancedMode: boolean;
  providers: INotificationProvider[];
  // request the widget data be refreshed after update
  requestRefresh: (widgets: IDisplayWidget[]) => void;
};

type WidgetState = {
  showConfigModal: boolean;
};

const Widget: React.FC<Props> = ({
  isNew,
  widget,
  advancedMode,
  providers,
  requestRefresh,
}) => {
  const [widgetState, setWidgetState] = useState({
    showConfigModal: false,
  } as WidgetState);

  const handleShow = () =>
    setWidgetState({
      showConfigModal: true,
    });

  const handleDuplicate = () => {
    const copyConfig = { ...widget.config } as any;
    copyConfig.widgetTitle = "Copy of " + copyConfig.widgetTitle;
    copyConfig.notificationRank++;
    copyConfig._id = undefined;

    updateWidgetConfig(copyConfig)
      .then((res) => {
        if (res.status !== 200) {
          throw Error("Error duplicating widget: " + res.status);
        }
        requestRefresh(res.data.widgets);
      })
      .catch((e) => {
        console.error("Error duplicating widget", e);
      });
  };

  function handleDelete() {
    deleteWidget(widget.config)
      .then((res) => {
        if (res.status != 200) {
          throw Error("Failed to delete widget: " + res.status);
        }
        requestRefresh(res.data.widgets);
      })
      .catch((e) => {
        console.error("Error deleting widget", e);
      });
  }

  let notificationDisplay;
  if (widget.notification) {
    const notification = widget.notification;
    let image;
    if (notification.imgUrl) {
      image = (
        <Image
          className="notification-image"
          fluid={true}
          src={notification.imgUrl}
        />
      );
    }
    if (widget.config.displayImageOnly && notification.imgUrl) {
      notificationDisplay = <Card.Body>{image}</Card.Body>;
    } else {
      let notificationBody;
      if (notification.description.indexOf("<advanced_format>") === 0) {
        // use new advanced format
        notificationBody = (
          <NotificationBodyFormat body={`${notification.description}`} />
        );
      } else {
        notificationBody = notification.description;
      }

      notificationDisplay = (
        <Card.Body>
          <h4>{notification.title}</h4>

          {notificationBody}
          {image}
        </Card.Body>
      );
    }
  }
  let linkDisplay;
  if (advancedMode) {
    linkDisplay = (
      <div>
        {/* <Card.Link href="/notifications">View More(TODO)</Card.Link> */}
        <Card.Link href="#" className="btn btn-success" onClick={handleShow}>
          Settings
        </Card.Link>
        <Card.Link
          href="#"
          className="btn btn-primary"
          onClick={handleDuplicate}
          hidden={widget === newWidget}
        >
          Duplicate
        </Card.Link>
        <Card.Link hidden={widget === newWidget}>
          <SpinnerButton
            varient="danger"
            text="Delete"
            onClick={handleDelete}
            hidden={isNew}
          />{" "}
        </Card.Link>
      </div>
    );
  }

  return (
    <Col xxl="3" lg="4" md="6">
      <Card className="widget">
        <Card.Body>
          <Card.Title hidden={!widget.config.showTitle}>
            {widget.config.widgetTitle}
          </Card.Title>

          {notificationDisplay}
          {linkDisplay}

          <WidgetConfigModal
            isNew={isNew}
            showModal={widgetState.showConfigModal}
            config={widget.config}
            providers={providers}
            requestRefresh={requestRefresh}
            requestHide={() => {
              setWidgetState({ showConfigModal: false });
            }}
          />
        </Card.Body>
      </Card>
    </Col>
  );
};

export default Widget;
