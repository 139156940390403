import React, { ChangeEvent, FormEvent, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { UpdateScheduleType } from "../../types";

type Props = {
  currentSchedule: IUpdateSchedule;
  onUpdatedSchedule: (update: IUpdateSchedule) => void;
};

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const WeeklyScheduleInput: React.FC<Props> = ({
  currentSchedule,
  onUpdatedSchedule,
}) => {
  let [schedule, setSchedule] = useState<WeekUpdateSchedule>(
    currentSchedule as WeekUpdateSchedule
  );
  if (schedule.schedule === undefined) {
    schedule.schedule = [];
  }

  function updateSchedule(s: WeekUpdateSchedule) {
    onUpdatedSchedule(s);
    setSchedule(s);
  }

  function onUpdateRow(index: number, day: number, minutes: number) {
    // convert to utc before sending to the server
    let d = new Date(2021, 7, 1 + day, Math.floor(minutes / 60), minutes % 60);

    // Sometimes the browser doesn't properly handle dst so we need this hack both here and below
    let offsetDiff = d.getTimezoneOffset() - new Date().getTimezoneOffset();
    if (offsetDiff !== 0) {
      d.setHours(d.getHours() - offsetDiff / 60);
    }
    schedule.schedule[index] = [
      d.getUTCDay(),
      d.getUTCHours() * 60 + d.getUTCMinutes(),
    ];

    updateSchedule({
      type: UpdateScheduleType.Week,
      schedule: schedule.schedule,
    });
  }

  function onDeleteRow(index: number) {
    schedule.schedule.splice(index, 1);

    updateSchedule({
      type: UpdateScheduleType.Week,
      schedule: schedule.schedule,
    });
  }

  function addNew() {
    onUpdateRow(schedule.schedule.length, 1, 9 * 60); // add new row monday at 9 am
  }

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Day</th>
            <th>Hour of day(24h)</th>
            <th>Minute of hour(0-59)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {schedule.schedule.map((r, i) => (
            <ScheduleRow
              key={i}
              index={i}
              dayUTC={r[0]}
              minutesUTC={r[1]}
              onUpdate={onUpdateRow}
              onDelete={onDeleteRow}
            />
          ))}
          <tr>
            <td>
              <Button onClick={addNew}>Add New</Button>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default WeeklyScheduleInput;

type RowProps = {
  index: number;
  dayUTC: number;
  minutesUTC: number;
  onUpdate: (index: number, day: number, minutes: number) => void;
  onDelete: (index: number) => void;
};
const ScheduleRow: React.FC<RowProps> = ({
  index,
  dayUTC,
  minutesUTC,
  onUpdate,
  onDelete,
}) => {
  let d = new Date(
    Date.UTC(2021, 7, 1 + dayUTC, Math.floor(minutesUTC / 60), minutesUTC % 60)
  );

  // Sometimes the browser doesn't properly handle dst so we need this hack
  let offsetDiff = d.getTimezoneOffset() - new Date().getTimezoneOffset();
  if (offsetDiff !== 0) {
    d.setHours(d.getHours() + offsetDiff / 60);
  }

  let day = d.getDay();
  let hours = d.getHours();
  let minOfHour = d.getMinutes();

  let minutes = d.getHours() * 60 + d.getMinutes();

  function onDayChange(e: FormEvent<HTMLSelectElement>) {
    onUpdate(index, e.currentTarget.selectedIndex, minutes);
  }

  function onHourChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.value.length === 0) return;
    let newHour = e.currentTarget.valueAsNumber;
    let newMin = newHour * 60 + minOfHour;
    onUpdate(index, day, newMin);
  }

  function onMinuteChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.value.length === 0) return;
    let newMin = hours * 60 + e.currentTarget.valueAsNumber;
    onUpdate(index, day, newMin);
  }

  function onDeleteClicked(e: any) {
    onDelete(index);
  }

  return (
    <tr>
      <td>
        <Form.Select
          defaultValue={days[day]}
          onChange={onDayChange}
          aria-label="Select Day of the week"
        >
          {days.map((v, i) => (
            <option key={i}>{v}</option>
          ))}
        </Form.Select>
      </td>
      <td>
        <Form.Control
          onChange={onHourChange}
          type="number"
          min="0"
          max="23"
          defaultValue={hours}
        />
      </td>
      <td>
        <Form.Control
          onChange={onMinuteChange}
          type="number"
          min="0"
          max="59"
          defaultValue={minOfHour}
        />
      </td>
      <td>
        <Button
          onClick={onDeleteClicked}
          className="btn-close"
          aria-label="Close"
        ></Button>
      </td>
    </tr>
  );
};
