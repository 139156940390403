import React, { useEffect, useState } from "react";
import { checkAuth } from "./API";
import LoginRegister from "./components/LoginRegister";
import NotificationProviderList from "./components/NotificationProviderList";
import PublicNotificationList from "./components/PublicNotificationList";
import SettingsPage from "./components/SettingsPage";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  redirect,
  Navigate,
} from "react-router-dom";
import PrivateNotificationList from "./components/PrivateNotificationList";
import PublicUserList from "./components/PublicUserList";
import WidgetView from "./components/widgets/WidgetView";

type AppState = {
  user?: User;
  notificationNumber?: number;
  initialLoadComplete: boolean;
  showAdvancedNotificationOptions?: boolean;
  hideNavBar?: boolean;
};

const App: React.FC = () => {
  var [appState, setAppState] = useState<AppState>({
    initialLoadComplete: false,
    showAdvancedNotificationOptions: false,
  });

  useEffect(() => {
    checkAuth()
      .then((obj) => {
        console.log("Auth success: " + obj);
        setAppState({
          user: obj.data.user,
          initialLoadComplete: true,
        });
      })
      .catch((err) => {
        console.log("Not authenticated");
        setAppState({ initialLoadComplete: true });
      });
  }, []);

  function onAuthenticated(user: User) {
    setAppState({
      user: user,
      initialLoadComplete: true,
    });
  }
  function onLogout() {
    setAppState({ initialLoadComplete: true });
  }
  const hideNavbar = () => {
    let newState = Object.assign({}, appState);
    newState.hideNavBar = true;
    setAppState(newState);
  };

  var exploreLink = (
    <LinkContainer to="/public/">
      <Nav.Link>Explore</Nav.Link>
    </LinkContainer>
  );

  var navbarButtons;
  var settingsLink;

  function toggleAdvancedNotificationOptions(event: any) {
    let newAppState = Object.assign({}, appState);
    newAppState.showAdvancedNotificationOptions =
      newAppState.showAdvancedNotificationOptions ? false : true;
    setAppState(newAppState);
  }

  if (appState.user !== undefined) {
    navbarButtons = (
      <Nav className="me-auto">
        <LinkContainer to="/widgets">
          <Nav.Link>Widgets</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/notifications">
          <Nav.Link>Notifications</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/notification-providers">
          <Nav.Link>Notification Providers</Nav.Link>
        </LinkContainer>
        {exploreLink}
      </Nav>
    );
    settingsLink = (
      <Nav className="">
        <Nav.Item>
          <Nav.Link
            active={appState.showAdvancedNotificationOptions!!}
            onClick={toggleAdvancedNotificationOptions}
          >
            Advanced Mode
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link active={false} onClick={hideNavbar}>
            Hide
          </Nav.Link>
        </Nav.Item>
        <LinkContainer to="/settings">
          <Nav.Link>
            Settings:{" "}
            <span style={{ textDecoration: "underline" }}>
              {appState.user!!.username}
            </span>
          </Nav.Link>
        </LinkContainer>
      </Nav>
    );
  } else {
    navbarButtons = <Nav className="me-auto">{exploreLink}</Nav>;
  }

  const loginRedirect = <Navigate to="/login" />;
  var mainPageState;
  if (appState.initialLoadComplete && appState.user !== undefined) {
    mainPageState = <Navigate to="/widgets" />;
  } else if (appState.initialLoadComplete) {
    mainPageState = loginRedirect;
  } else {
    mainPageState = <p>Loading please wait...</p>;
  }

  var settingsPage;
  if (appState.user !== undefined) {
    settingsPage = <SettingsPage user={appState.user!!} onLogout={onLogout} />;
  } else {
    settingsPage = loginRedirect;
  }

  var loginRegisterPage;
  if (appState.user) {
    loginRegisterPage = <Navigate to="/widgets" />;
  } else {
    loginRegisterPage = <LoginRegister onAuthenticated={onAuthenticated} />;
  }

  var notificationProviderPage;
  if (!appState.initialLoadComplete) {
    notificationProviderPage = "Loading...";
  } else if (appState.user) {
    notificationProviderPage = <NotificationProviderList />;
  } else {
    notificationProviderPage = loginRedirect;
  }

  let widgetPage;
  if (!appState.initialLoadComplete) {
    widgetPage = "Loading...";
  } else if (appState.user) {
    widgetPage = (
      <div className="widget-section container">
        <WidgetView
          user={appState.user}
          advancedMode={appState.showAdvancedNotificationOptions || false}
        ></WidgetView>
      </div>
    );
  }

  var notificationListPage;
  if (!appState.initialLoadComplete) {
    notificationListPage = "Loading...";
  } else if (appState.user) {
    notificationListPage = (
      <div className="notification-section container">
        <PrivateNotificationList
          user={appState.user!!}
          showAdvancedOptions={
            appState.showAdvancedNotificationOptions || false
          }
        />
      </div>
    );
  } else {
    notificationListPage = loginRedirect;
  }

  return (
    <main className="App ">
      <Router>
        <Navbar
          collapseOnSelect
          bg="light"
          expand="lg"
          className="main-navbar"
          hidden={appState.hideNavBar}
        >
          <Container>
            <Navbar.Brand className="navbar-brand" href="/">
              Notification Informer{" "}
              <span className="badge bg-secondary">
                {appState.notificationNumber}
              </span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarSupportedContent">
              <span className="navbar-toggler-icon"></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="navbarSupportedContent">
              {navbarButtons}
              {settingsLink}
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="main-container">
          <Routes>
            <Route path="/" element={mainPageState} />
            <Route
              path="/public/:username"
              element={
                <PublicNotificationList
                  isLoggedIn={appState.user !== undefined}
                  showAdvancedOptions={
                    appState.showAdvancedNotificationOptions || false
                  }
                />
              }
            />
            <Route path="/public/" element={<PublicUserList />} />
            <Route path="/widgets" element={widgetPage} />
            <Route path="/notifications" element={notificationListPage} />
            <Route path="/settings" element={settingsPage} />
            <Route path="/login" element={loginRegisterPage} />
            <Route
              path="/notification-providers"
              element={notificationProviderPage}
            />
          </Routes>
        </div>
      </Router>
    </main>
  );
};

export default App;
