import React from "react";
import NotificationBodyFormat from "./NotificationBodyFormat";

type Props = NotificationProps & {
  deleteNotification?: (_id: string) => void;
  disableAnimation: boolean;
};

const NotificationItem: React.FC<Props> = ({
  notification,
  deleteNotification,
  disableAnimation,
}) => {
  var [state, setState] = React.useState(false);
  function remove() {
    setState(true);
    setTimeout(() => {
      if (deleteNotification) {
        deleteNotification(notification._id);
      }
    }, 100);
  }
  let image;
  // verify valid source image
  if (
    notification.imgUrl &&
    notification.imgUrl.toLocaleLowerCase().includes("http")
  ) {
    image = (
      <img className="notification-image" src={notification.imgUrl!!} alt="" />
    );
  } else {
    image = <div></div>;
  }

  let dismissButton = <div></div>;
  if (deleteNotification) {
    // only show delete button if we get a callback to allow deletion
    dismissButton = (
      <div className="col-1 dissmiss-btn">
        <button
          onClick={() => remove()}
          className="btn-close"
          aria-label="Close"
        ></button>
      </div>
    );
  }

  var title;
  if (notification.webUrl) {
    title = (
      <a
        style={{ color: "black" }}
        href={`${notification.webUrl}`}
        target="_blank"
        rel="noreferrer"
      >
        {notification.title}
      </a>
    );
  } else {
    title = <div>{notification.title}</div>;
  }

  let newDescription = notification.description;
  let startIndex = newDescription.indexOf('{"');
  let descriptionHtml = <div></div>;
  if (newDescription === "_") {
    // Skip adding blank description
  } else if (newDescription.indexOf("<advanced_format>") === 0) {
    // use new advanced format
    descriptionHtml = <NotificationBodyFormat body={`${newDescription}`} />;
  } else {
    // TODO remove this junk and use advanced format
    if (startIndex !== -1) {
      let startBracket = "{";
      let endBracket = "}";

      //check for an array
      let arrayStartIndex = newDescription.indexOf("[{");
      if (arrayStartIndex !== -1) {
        startIndex = arrayStartIndex;
        startBracket = "[";
        endBracket = "]";
      }

      //startIndex += 4;
      var endIndex = startIndex;
      var openBracketCount = 1;
      for (; endIndex < newDescription.length && openBracketCount > 0; ) {
        endIndex++;
        let char = newDescription.charAt(endIndex);
        if (char === startBracket) openBracketCount++;
        else if (char === endBracket) openBracketCount--;
      }
      console.log(
        "parse: " + newDescription.substring(startIndex, 1 + endIndex)
      );
      let json = JSON.stringify(
        JSON.parse(newDescription.substring(startIndex, 1 + endIndex)),
        null,
        2
      );
      console.log("pretty: " + json);
      descriptionHtml = (
        <div className="card-body">
          <div className="notification-description">
            {newDescription.substring(0, startIndex)}
            <pre>{json}</pre>
            {newDescription.substring(endIndex + 1)}
          </div>
        </div>
      );
    } else {
      descriptionHtml = (
        <div className="card-body">
          <div className="notification-description">{newDescription}</div>
        </div>
      );
    }
  }

  let date = new Date(notification.createdAt!!);
  let updatedDateText = undefined;
  if (
    notification.updatedAt &&
    notification.updatedAt !== notification.createdAt
  ) {
    // notification was updated
    let updatedDate = new Date(notification.updatedAt!!);
    updatedDateText = (
      <span className="notification-date">
        &nbsp;| Updated: {updatedDate.toLocaleDateString()}{" "}
        {updatedDate.toLocaleTimeString()}
      </span>
    );
  }
  let actual = (
    <div
      className="notification card"
      style={{
        animation: `${
          disableAnimation ? "" : state ? "fadeOut 0.4s" : "fadeIn 1.5s"
        }`,
      }}
    >
      <div>
        <div className="card-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-11">
                <h4 className="notification-title">{title}</h4>
                <h6 className="card-subtitle mb-2 text-muted">
                  <span className="notification-type">
                    Type: {notification.type} |{" "}
                  </span>
                  <span className="notification-date">
                    Created: {date.toLocaleDateString()}{" "}
                    {date.toLocaleTimeString()}
                  </span>
                  {updatedDateText}
                </h6>
              </div>
              {dismissButton}
            </div>
          </div>
        </div>
        {descriptionHtml}
        {image}
      </div>
    </div>
  );
  return actual;
};

export default NotificationItem;
